<div class="section-content section-content-p30">
	<div class="container">
		<div class="row" style="padding:20px;">
			<div class="col">
				<a href="/check-products" class="btn btn-sm" style="background-color: #088178; color: #fff;">Product
					Lists</a>
			</div>
		</div>

		<div class="row" style="padding:20px;">
			<h2>Add Product</h2><br><br>
			<form class="form" (ngSubmit)="onSubmit()" [formGroup]="productForm">
				<div class="form-group">
					<input type="hidden" id="sellerid" value="{{userId}}" class="form-control" ngModel>
				</div>
				<div class="form-group">
					<label for="catid">Category</label><br>
					<!-- <input type="text" id="catid" formControlName="catid" class="form-control" ngModel> -->
					<select class="form-select" id="catid" formControlName="catid">
						<option value="{{tempProductCategory.catid}}"
							*ngFor="let tempProductCategory of productCategories">{{tempProductCategory.categoryname}}
						</option>
					</select>
				</div>
				<br>
				<div class="form-group">
					<label for="name">Product Name</label><br>
					<input type="text" id="name" formControlName="name" class="form-control" ngModel>
					<div class="grid mt-1" *ngIf="(productForm.get('name')?.invalid && isSubmitted)">
						<div class="errMsg">Please enter the product name to proceed.</div>
					</div>
				</div>
				<br>
				<div class="form-group">
					<label for="desc">Product Description</label><br>
					<input type="text" id="description" formControlName="description" class="form-control" ngModel>
					<div class="grid mt-1" *ngIf="(productForm.get('description')?.invalid && isSubmitted)">
						<div class="errMsg">Please enter the product description to proceed.</div>
					</div>
				</div>
				<br>
				<div class="form-group">
					<label for="image">Product Image</label><br>
					<button type="button" class="btn btn-sm" style="background-color: #B6D0E2; color: #fff;"
						(click)="onUpload()">Upload</button>
					<img *ngIf="selectedImageUrl" [src]="selectedImageUrl"
						style="margin-left: 10px; width: 100px; height:100px" />
					<div class="grid mt-1"
						*ngIf="((selectedImageUrl == null || selectedImageUrl == '') && isSubmitted)">
						<div class="errMsg">Please upload an image of the product to proceed.</div>
					</div>
				</div>
				<br>
				<div class="form-group">
					<label for="price">Unit Price ($)</label><br>
					<input type="number" id="unitPrice" formControlName="unitPrice" class="form-control" min="0"
						step=".01" (input)="validatePrice($event)" ngModel>
					<div class="grid mt-1" *ngIf="(productForm.get('unitPrice')?.invalid && isSubmitted)">
						<div class="errMsg">Please enter the unit price to proceed.</div>
					</div>
				</div>
				<br>
				<div class="form-group">
					<label for="stock">Unit Stock</label><br>
					<input type="number" id="unitsInStock" formControlName="unitsInStock" class="form-control" step="1"
						min="0" (input)="validateStock($event)" ngModel>
					<div class="grid mt-1" *ngIf="(productForm.get('unitsInStock')?.invalid && isSubmitted)">
						<div class="errMsg">Please enter the unit stock to proceed.</div>
					</div>
				</div>
				<br>
				<button type="submit" class="btn checkout-button"
					style="background-color: #088178; color: #fff;">Submit</button>
			</form>
		</div>
	</div>
</div>

<!--IMAGE DIALOG-->
<div class="popup-overlay" *ngIf="showImagePopup">
	<div class="popup-content">
		<label class="popup-title">Product Image</label>
		<div class="popup-field">
			<div class="image-gallery">
				<div *ngFor="let image of images" class="image-item">
					<!-- <img [src]="image.imageUrl" (click)="selectImage(image.imageId)" /> -->
					<img [src]="image.imageUrl" [alt]="image.description" (click)="selectImage(image.imageid)" />
				</div>
			</div>
			<div class="popup-actions">
				<button type="button" class="btn btn-sm" style="background-color: #b30909; color: #fff;"
					(click)="close()">Close</button>
			</div>
		</div>
	</div>