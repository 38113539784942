<div class="section-p1">
    <div class="section-content section-content-p30">
        <div class="container-fluid">

            <div *ngIf="cartItem.length > 0;else empty">
                <table class="table">
                    <thead class="thead-light">
                        <tr>
                            <th width="35%">Product Name</th>
                            <th width="15%">Quantity</th>
                            <th width="30%">Subtotal</th>
                            <th width="20%"></th>
                        </tr>
                    </thead>
                    <tr *ngFor="let tempCartItem of cartItem">
                        <td>
                            <p>{{ tempCartItem.name }}</p>
                            <p>{{ tempCartItem.unitPrice | currency:'SGD' : 'symbol-narrow' }}</p>
                        </td>
                        <td>
                            <div class="items">
                                <div class="row no-gutters">
                                    <div class="col">
                                        <button (click)="decrementQuantity(tempCartItem)"
                                            class="btn btn-secondary btn-sm">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                    <div class="col pr-2 pl-2">
                                        {{ tempCartItem.quantity }}
                                    </div>
                                    <div class="col">
                                        <button (click)="incrementQuantity(tempCartItem)"
                                            class="btn btn-secondary btn-sm">
                                            <i class="fas fa-plus"></i>
                                        </button>
                                    </div>
                                    <div class="col-8"></div>
                                </div>
                                <div class="row">
                                    <small class="text-danger" style="display: none;"
                                        id="cart-item[{{tempCartItem.pdtid}}]"><i>Unit in stocks:
                                            {{tempCartItem.stockQty}}</i></small>
                                </div>
                            </div>
                        </td>
                        <td>
                            <p class="mt-2">{{ tempCartItem.quantity * tempCartItem.unitPrice | currency:'SGD' :
                                'symbol-narrow' }}</p>
                        </td>
                    </tr>

                    <tr>
                        <td colspan="3">
                            <div style="display: flex; flex-direction: column; align-items: flex-end;">
                                <p><b>Total Quantity:</b> {{ totalQuantity }}</p>
                                <p><b>Shipping:</b> Free</p>
                                <p><b>Total Price:</b> {{ totalPrice | currency:'SGD' : 'symbol-narrow' }}</p>
                                <ng-template [ngIf]="!isSeller">
                                    <button routerLink="/checkout" class="btn"
                                        style="background-color: #088178; color: #fff;"
                                        [disabled]="!isLoggedIn">Checkout</button>
                                </ng-template>
                                <br>
                                <small *ngIf="!isLoggedIn" class="text-danger">Please <a routerLink="/auth"
                                        class="text-decoration-none">Login</a> to proceed</small>
                            </div>
                        </td>

                    </tr>
                </table>
            </div>

            <ng-template #empty>
                <div class="col-md-12" role="alert">Your Shopping Cart is Empty!</div>
            </ng-template>
        </div>
    </div>
</div>