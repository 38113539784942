import { Component } from '@angular/core';
import { ProductCategory } from 'src/app/common/product-category';
import { ProductService } from 'src/app/services/product/product.service';

@Component({
  selector: 'app-product-category-menu',
  templateUrl: './product-category-menu.component.html',
  styleUrls: ['./product-category-menu.component.css']
})
export class ProductCategoryMenuComponent {
  productCategories: ProductCategory[] = [];

  constructor(private productService: ProductService) { }

  ngOnInit(): void {
    this.listProductCategories();
  }

  listProductCategories() {
    // this.productService.getProductCategories().subscribe(
    //   data => {
    //     // console.log("Product Categories="+JSON.stringify(data));
    //     this.productCategories = data;
    //   }
    // );

    // sample data
    this.productCategories = [
      {
        catid: "C11",
        categoryname: "BOOK"
      },
      {
        catid: "C12",
        categoryname: "PEN"
      },
      {
        catid: "C13",
        categoryname: "ERASER"
      }
    ];
  }
}
