<section id="header">
  <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" />
  <a href="index.html">
    <img src="/assets/image/logo.png" class="logo" alt="SmartCart Logo" />
  </a>
  <h2>Welcome to SmartCart</h2>
  <div id="nav-container">
    <ul id="navbar">
      <li>
        <a routerLink="/auth" routerLinkActive="active" class="{{isDisabled}}">{{(isLoggedIn&&isAuth&&user!=null)?userName:"Login"}}</a>
      </li>
      <li><a routerLink="/index" routerLinkActive="active">Home</a></li>
      <li><a routerLink="{{routeName}}" routerLinkActive="active">{{isSeller?'Add Product':'Shop'}}</a></li>
      <li><a routerLinkActive="active">About</a></li>
      <li *ngIf="!isSeller">
        <app-cart-status></app-cart-status>
      </li>
    </ul>
  </div>
</section>

<router-outlet></router-outlet>

<footer>
  <ul>
    <li><a href="#">About Us</a></li>
    <li><a href="#">Contact Us</a></li>
    <li><a href="#">Help</a></li>
  </ul>
</footer>
