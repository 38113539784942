<section id="cart" class="section-p1">

  <table width="100%">
  	<thead>
  		<tr>
  			<td>Remove</td>
  			<td></td>
  			<td>Product</td>
  			<td>Price</td>
  			<td>Quantity</td>
  			<td>Subtotal</td>
  		</tr>
  	</thead>
  	<tbody>
  		<tr>
  			<td><a><i class="fas fa-trash"></i></a></td>
  			<td><img src="/assets/image/cracker.png" alt=""></td>
  			<td>Munchy's Lexus Sandwich Calcium Crackers - Vegetable</td>
  			<td>$1.00</td>
  			<td><input type="number" value="1"></td>
  			<td>$1.00</td>
  		</tr>
  		
  		<tr>
  			<td><a><i class="fas fa-trash"></i></a></td>
  			<td><img src="/assets/image/mixedveg.png" alt=""></td>
  			<td>FairPrice Frozen Fresh Mixed Vegetables</td>
  			<td>$1.00</td>
  			<td><input type="number" value="1"></td>
  			<td>$1.00</td>
  		</tr>
  		
  		<tr>
  			<td><a><i class="fas fa-trash"></i></a></td>
  			<td><img src="/assets/image/oil.png" alt=""></td>
  			<td>FairPrice Vegetable Oil</td>
  			<td>$1.00</td>
  			<td><input type="number" value="1"></td>
  			<td>$1.00</td>
  		</tr>
  		
  	</tbody>
  
  </table>
</section>

<section id ="cart-add" class="section-p1">
	<div id="placehold">
		<h3>Something Here</h3>
		<div>
			<input type="text" placeholder="Enter Something">
			<button class="normal">Enter</button>
		</div>
	</div>
	
	<div id="subtotal">
		<h3>Proceed to Checkout</h3>
		<table>
			<tr>
				<td>Cart Subtotal</td>
				<td>$3.00</td>
			</tr>
			
			<tr>
				<td>Shipping Fee</td>
				<td>Free</td>
			</tr>
			
			<tr>
				<td><strong>Total</strong></td>
				<td><strong>$3.00</strong></td>
			</tr>
		</table>
		<button>Checkout</button>
	</div>

</section>