<div class="section-p1">
    <div class="section-content section-content-p30">

        <label class="popup-title">Below are your order details:</label>

        <!-- Order Details -->
        <div class="form-area">
            <p><b>Products:</b></p>
            <div class="product-list">
                <div *ngFor="let item of products; let i = index" class="product-item">
                    <p>
                        {{ i + 1 }}. {{ item.name }} 
                        <span class="price">{{ item.unitPrice | currency:'SGD' : 'symbol-narrow' }}</span>
                        <span class="quantity">x{{ item.quantity }}</span>
                    </p>
                </div>
            </div>
            
            
            <p><b>Total Quantity:</b> {{ totalQuantity }}</p>
            <p><b>Shipping:</b> Free</p>
            <p><b>Payment:</b> Cash On Delivery</p>
            <p><b>Total Price:</b> {{ totalPrice | currency:'SGD' : 'symbol-narrow' }}</p>
        </div>

        <div>
            <button type="submit" (click)="onSubmit()" [disabled]="customer.name==null" routerLink="/products"
                class="btn" style="background-color: #088178; color: #fff;">{{customer.name==null?"Loading...":"Purchase
                Now"}}</button>
        </div>


    </div>
</div>