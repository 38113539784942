<section id="dashboard">
  <h4>At your fingertips</h4>
  <h1>Get Fresh</h1>
  <h2>Get Healthy</h2>

  <ng-template [ngIf]="userRole == 'S'" [ngIfElse]="customerRole">
    <p>Welcome Back, <b>{{userName}}</b></p>
    <button routerLink="/addproduct">Add Product</button>
  </ng-template>
  <ng-template #customerRole>
    <p>Start shopping now, <b>{{userName}}</b></p>
    <button routerLink="/products">Shop Now</button>
  </ng-template>
</section>