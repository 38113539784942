<div class="container">
    <div class="row">
        <div id="product-details">
            <div class="single-pro-img">
                <img src="{{ product.imageUrl }}" alt="" id="MainImg">
            </div>

            <div class="single-pro-details">
                <div class="plabel">{{ product.categoryname }}</div>
                <h4>{{ product.name }}</h4>
                <h2>{{ product.unitPrice | currency:'SGD' : 'symbol-narrow' }}</h2>
                <button type="button" (click)="addToCart()" class="btn btn-sm" [disabled]="outOfStock">Add to Cart</button>
                <br>
                <div></div>
                <span class="text-danger" *ngIf="outOfStock; else inStock"><i>Out of Stock</i></span>
                <ng-template #inStock>
                    <span id="showStockQty" [ngStyle]="{'color': isLowStock ? 'red' : 'black'}">
                        <i *ngIf="product.unitsInStock! > 0">Units in stock: {{product.unitsInStock}}</i>
                      </span>
                      
                </ng-template>
                <div class="prodLabel">Product Details</div>
                <span>{{ product.description }}</span>
            </div>
            <br>
        </div>
        <div>
            <button class="btn btn-sm" id="back-button" routerLink="/products">Back</button>
        </div>
    </div>
</div>
